main.controller("MembersController", [
  "$rootScope",
  "$stateParams",
  "$scope",
  "MembersService",
  "LoansService",
  "DepartmentsService",
  "BenefitsService",
  "RegistrationFeesService",
  "notify",
  "$http",
  "$upload",
  "$state",
  function (
    $rootScope,
    $stateParams,
    $scope,
    MembersService,
    LoansService,
    DepartmentsService,
    BenefitsService,
    RegistrationFeesService,
    notify,
    $http,
    $upload,
    $state
  ) {
    "use strict";

    /* Shared Code across pages */

    $scope.memberId = $stateParams.memberId;


    // Pagination Column Index
    $scope.serial = 1;
    $scope.indexCount = function (newPageNumber) {
      $scope.itemPerPage = $scope.rowNumber.selected.value;
      $scope.serial =
        newPageNumber * $scope.itemPerPage - ($scope.itemPerPage - 1);
    };


    // Tabs for pages
    var tabClasses;

    function initTabs() {
      tabClasses = ["", "", "", "", "", ""];
    }

    $scope.getTabClass = function (tabNum) {
      var n = tabNum;
      return tabClasses[tabNum];
    };

    $scope.getTabPaneClass = function (tabNum) {
      return "tab-pane " + tabClasses[tabNum];
    };

    $scope.setActiveTab = function (tabNum) {
      initTabs();
      tabClasses[tabNum] = "active";
    };

    initTabs();
    $scope.setActiveTab(1);


    // System User Status filter
    $scope.is_active_filter = {};
    $scope.is_active_filter.selected = { label: "All", value: "" };
    $scope.is_active_filters = [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Inactive", value: "0" }
    ];

    // Membership Status filter
    $scope.membership_status_filter = {};
    $scope.membership_status_filter.selected = { label: "Current", value: "1" };
    $scope.membership_status_filters = [
      { label: "All", value: "" },
      { label: "Current", value: "1" },
      { label: "Former", value: "0" }
    ];

    // Rows Per Page Filter
    $scope.rowNumber = {};
    $scope.rowNumber.selected = { label: 10, value: 10 };
    $scope.rowNumbers = [
      { label: 10, value: 10 },
      { label: 30, value: 30 },
      { label: 50, value: 50 },
      { label: "All", value: "" }
    ];

    // Order Filter
    $scope.setOrderProperty = function (propertyName) {
      if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = "-" + propertyName;
      } else if ($scope.orderProperty === "-" + propertyName) {
        $scope.orderProperty = propertyName;
      } else {
        $scope.orderProperty = propertyName;
      }
    };


    $(function () {
      $('input[name="date_of_birth"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        startDate: moment().startOf("hour"),
        endDate: moment()
          .startOf("hour")
          .add(32, "hour"),
        locale: {
          format: "YYYY-MM-DD",
          firstDay: 1
        }
      });

      $('input[name="date_of_birth_save"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        startDate: moment().startOf("hour"),
        endDate: moment()
          .startOf("hour")
          .add(32, "hour"),
        locale: {
          format: "YYYY-MM-DD",
          firstDay: 1
        }
      });

      $('input[name="date_of_birth2_save"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        startDate: moment().startOf("hour"),
        endDate: moment()
          .startOf("hour")
          .add(32, "hour"),
        locale: {
          format: "YYYY-MM-DD",
          firstDay: 1
        }
      });

      $('input[name="reg_date_save"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        startDate: moment().startOf("hour"),
        endDate: moment()
          .startOf("hour")
          .add(32, "hour"),
        locale: {
          format: "YYYY-MM-DD",
          firstDay: 1
        }
      });

      $('input[name="repayment_month"]').daterangepicker({
        singleDatePicker: true,
        startDate: moment().startOf("hour"),

        locale: {
          format: "MMMM YYYY",
          firstDay: 1
        }
      });

      $('input[name="preclosure_month"]').daterangepicker({
        singleDatePicker: true,
        startDate: moment().startOf("hour"),

        locale: {
          format: "MMMM YYYY",
          firstDay: 1
        }
      });

      $('input[name="effective_date"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        startDate: moment().startOf("hour"),
        endDate: moment()
          .startOf("hour")
          .add(32, "hour"),
        locale: {
          format: "YYYY-MM-DD",
          firstDay: 1
        }
      });

      $('input[name="membership_renewal_date"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        startDate: moment().startOf("hour"),
        endDate: moment()
          .startOf("hour")
          .add(32, "hour"),
        locale: {
          format: "YYYY-MM-DD",
          firstDay: 1
        }
      });

    });


    $(function () {
      $scope.dateRange = function (range) {
        var daterange = range.split("-");
        $scope.dateFrom = Date.parse(daterange["0"]);
        $scope.dateTo = Date.parse(daterange["1"]);
      };

      $scope.dateRange2 = function (range) {
        var daterange = range.split("-");
        $scope.dateFrom2 = Date.parse(daterange["0"]);
        $scope.dateTo2 = Date.parse(daterange["1"]);
      };

      $scope.dateRange3 = function (range) {
        var daterange = range.split("-");
        $scope.dateFrom3 = Date.parse(daterange["0"]);
        $scope.dateTo3 = Date.parse(daterange["1"]);
      };

      var start = moment().subtract(29, "days");
      var end = moment();

      function cb(start, end) {
        $("#reportrange span").html(
          start.format("YYYY-MM-DD") + "-" + end.format("YYYY-MM-DD")
        );
      }

      $("#reportrange").daterangepicker(
        {
          locale: {
            format: "MMM DD,YYYY",
            firstDay: 1
          },
          showDropdowns: true,
          startDate: start,
          endDate: end,

          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Year": [
              moment()
                .subtract(1, "year")
                .startOf("year"),
              moment()
                .subtract(1, "year")
                .endOf("year")
            ]
          }
        },
        cb
      );

      cb(start, end);

      function cbc(start, end) {
        $("#reportrange2 span").html(
          start.format("YYYY-MM-DD") + "-" + end.format("YYYY-MM-DD")
        );
      }

      $("#reportrange2").daterangepicker(
        {
          locale: {
            format: "MMM DD,YYYY",
            firstDay: 1
          },
          showDropdowns: true,
          startDate: start,
          endDate: end,

          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Year": [
              moment()
                .subtract(1, "year")
                .startOf("year"),
              moment()
                .subtract(1, "year")
                .endOf("year")
            ]
          }
        },
        cbc
      );

      cbc(start, end);

      function cba(start, end) {
        $("#reportrange3 span").html(
          start.format("YYYY-MM-DD") + "-" + end.format("YYYY-MM-DD")
        );
      }

      $("#reportrange3").daterangepicker(
        {
          locale: {
            format: "MMM DD,YYYY",
            firstDay: 1
          },
          showDropdowns: true,
          startDate: start,
          endDate: end,

          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Year": [
              moment()
                .subtract(1, "year")
                .startOf("year"),
              moment()
                .subtract(1, "year")
                .endOf("year")
            ]
          }
        },
        cba
      );

      cba(start, end);
    });


    $scope.checkPdfExtension = function (filename) {
      return filename.split(".").pop();
    };


    $scope.showMembers = function (){
      MembersService.showMembers().success(function (data) {
        $scope.members = data.members;
      });
    }

    $scope.showCurrentMembers = function (){
      MembersService.showCurrentMembers().success(function (data) {
        $scope.members = data.members;
      });
    }

    MembersService.getUserAuthorization().success(function (data, status) {
      var abilities = data.abilities;
      var fobidden = data.fobidden;
      $scope.viewMemberInformationOnly = false;

      angular.forEach(abilities, function (abilities) {
        if (abilities.name == "Basic User") {
          $scope.viewMemberInformationOnly = true;
        }
        if (abilities.name == "Create Transactions") {
          $scope.createTransactions = true;
        }
        if (abilities.name == "Import Transactions") {
          $scope.importTransactions = true;
        }
        if (abilities.name == "Add Members") {
          $scope.reg_member = "Yes";
        }
        if (abilities.name == "Import Members") {
          $scope.imp_member = "Yes";
        }
        if (abilities.name == "Export Members") {
          $scope.exp_member = "Yes";
        }
        if (abilities.name == "Activate Members") {
          $scope.act_member = "Yes";
        }
        if (abilities.name == "View Members") {
          $scope.getinfo_member = "Yes";
        }
        if (abilities.name == "Export Transactions") {
          $scope.export_transactions = "Yes";
        }
      });

      angular.forEach(fobidden, function (fobidden) {
        if (fobidden.name == "Add Members") {
          $scope.reg_member = "No";
        }
        if (fobidden.name == "Import Members") {
          $scope.imp_member = "No";
        }
        if (fobidden.name == "Export Members") {
          $scope.exp_member = "No";
        }
        if (fobidden.name == "Activate Members") {
          $scope.act_member = "No";
        }
        if (fobidden.name == "View Members") {
          $scope.getinfo_member = "No";
        }
        if (fobidden.name == "Export Transactions") {
          $scope.export_transactions = "No";
        }
      });
    });



    /* Contributions page */

    $scope.contributions_sample_data = [
      {
        "EFFECTIVE DATE": "2019-03-14",
        "MEMBER ID": "1",
        NAME: "Juma Emmanuel Agustino",
        DEPOSIT: "8000",
        SHARE: "100000",
        CONTRIBUTION: "2000",
        SAVING: "50000",
        "LOAN ID": "5",
        "REPAYMENT MONTH": "2019-03-01",
        "LOAN PRINCIPAL": "10000",
        INTEREST: "1000",
        PRECLOSURE: "No"
      }
    ];

    $scope.saveAsXlsx = function () {
      alasql(
        'SELECT * INTO XLSX("contributions-sample.xlsx",{headers:true}) FROM ?',
        [$scope.contributions_sample_data]
      );
    };


    // Filter in Contributions page
    $scope.trans_type = {};
    $scope.trans_type.selected = { label: "All", value: "" };
    $scope.trans_types = [
      { label: "All", value: "" },
      { label: "Contributions", value: "Contribution" },
      { label: "Deposits", value: "Deposit" },
      { label: "Savings", value: "Saving" },
      { label: "Shares", value: "Share" }
    ];


    $scope.importTransactionsExcel = function (sheet) {
      $upload
        .upload({
          url: "importTransactionsExcel",
          data: {
            sheet: sheet
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getContributionTransactions();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Get contributions in Contributions page
    $scope.getContributionTransactions = function() {
      MembersService.getContributionTransactions().success(function (data) {
        $scope.transactionsData = data.transactionsData;
      });
    }


    // Show active deductions in Add Contributions form
    $scope.getActiveDeductions = function () {
      MembersService.getActiveDeductions().success(function (data) {
        $scope.active_deductions = data.active_deductions;
      });
    };


    $scope.getAddContributionState = function () {
      var deductions = $scope.active_deductions;

      angular.forEach(deductions, function (deductions) {
        if (deductions.transaction_type == $scope.formdata.type) {
          $scope.min_amount = deductions.min_amount;
          $scope.max_amount = deductions.max_amount;
        }
      });

      var amount = Number($scope.formdata.amount);
      var max_amount = Number($scope.max_amount);
      var min_amount = Number($scope.min_amount);
      $scope.above_max = amount > max_amount;
      $scope.below_min = amount < min_amount;
    };


    $scope.saveContribution = function (
      formdata,
      memberId,
      receipt_copy
    ) {
      $upload
        .upload({
          url: "saveContribution",
          data: {
            formdata: formdata,
            memberId: memberId,
            receipt_copy: receipt_copy
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // Repopulate table
          $scope.getContributionTransactions();

          // Clear modal
          $scope.clearAddContributionModal();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Clear Save Contribution modal
    $scope.clearAddContributionModal = function() {
      $scope.formdata = '';
      angular.element("input[type='file']").val(null);
      $scope.receipt_copy = {};
      $scope.min_amount = null;
      $scope.max_amount = null;

      if ($scope.members.id){
        $scope.members.id.selected = '';
      }

      $scope.Trans.$setPristine();
      $scope.Trans.$setUntouched();

    }


    $scope.getTransDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getTransDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata2 = data.transaction;
      });
    };



    /* Repayments page */

    $scope.loan_repayments_sample_data = [
      {
        "EFFECTIVE DATE": "2019-03-14",
        "MEMBER ID": "1",
        NAME: "Juma Emmanuel Agustino",
        "LOAN ID": "5",
        "REPAYMENT MONTH": "2019-03-01",
        "REPAYMENT AMOUNT": "50000",
        "PRINCIPAL REPAYMENT": "40000",
        "INTEREST REPAYMENT": "10000",
        PRECLOSURE: "No"
      }
    ];

    $scope.loan_repayments_sample = function () {
      alasql(
        'SELECT * INTO XLSX("loan-repayments-sample.xlsx",{headers:true}) FROM ?',
        [$scope.loan_repayments_sample_data]
      );
    };


    $scope.importRepaymentsExcel = function (sheet) {
      $upload
        .upload({
          url: "importRepaymentsExcel",
          data: {
            sheet: sheet
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getRepaymentTransactions();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Get repayments in Repayments page
    $scope.getRepaymentTransactions = function() {
      MembersService.getRepaymentTransactions().success(function (data) {
        $scope.repayments = data.repayments;
      });
    }


    $scope.saveRepayment = function (
      formdata,
      loanInfo,
      receipt_copy,
      installment,
      principal_per_month,
      interest_per_month,
      multiplication_factor,
      repayment_month
    ) {
      angular.extend(
        formdata,
        { installment: installment },
        { principal_per_month: principal_per_month },
        { interest_per_month: interest_per_month },
        { multiplication_factor: multiplication_factor },
        { repayment_month: repayment_month }
      );
      $upload
        .upload({
          url: "saveRepayment",
          data: {
            formdata: formdata,
            loanInfo: loanInfo,
            receipt_copy: receipt_copy
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // Repopulate table
          $scope.getRepaymentTransactions();

          // Clear modal
          $scope.clearAddRepaymentModal();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Clear Save Repayment modal
    $scope.clearAddRepaymentModal = function() {
      $scope.formdata = '';
      if ($scope.loans){
        $scope.loans.selected = '';
      }
      $scope.installment = null;
      $scope.principal_per_month = null;
      $scope.interest_per_month = null;
      $scope.multiplication_factor = null;
      $scope.current_balance = null;
      $scope.repayment_month = '';
      angular.element("input[type='file']").val(null);
      $scope.receipt_copy = {};
      $scope.Trans.$setPristine();
      $scope.Trans.$setUntouched();
    }
    
    $scope.savePreclosure = function (
      formdata,
      loan_info,
      receipt_copy,
      pre_closure_amount,
      total_principal_outstanding,
      total_interest_preclosure,
      preclosure_month
    ) {
      angular.extend(
        formdata,
        { pre_closure_amount: pre_closure_amount },
        { total_principal_outstanding: total_principal_outstanding },
        { total_interest_preclosure: total_interest_preclosure },
        { preclosure_month: preclosure_month }
      );

      $upload
        .upload({
          url: "savePreclosure",
          data: {
            formdata: formdata,
            loan_info: loan_info,
            receipt_copy: receipt_copy
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // Repopulate table
          $scope.getRepaymentTransactions();

          // Clear modal
          $scope.clearAddPreclosureModal();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Clear Save Preclosure modal
    $scope.clearAddPreclosureModal = function() {
      $scope.formdata = '';
      if ($scope.loans){
        $scope.loans.selected = '';
      }
      $scope.pre_closure_amount = null;
      $scope.total_principal_outstanding = null;
      $scope.total_interest_preclosure = null;
      $scope.preclosure_month = '';
      angular.element("input[type='file']").val(null);
      $scope.receipt_copy = {};
      $scope.Preclosure.$setPristine();
      $scope.Preclosure.$setUntouched();
    }


    $scope.getRepaymentDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getRepaymentDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.repayment_details = data.repayment_details;
      });
    };


    $scope.afterSelect = function ($selected) {
      $scope.multiplication_factor = 1;
      document.getElementById("multiplicationFactor").value =
        $scope.multiplication_factor;

      // For Adding Loan Repayment
      $scope.interest_per_month = $selected.interest_per_month;
      $scope.current_balance = $selected.current_balance;
      $scope.total_interest = $selected.interest;
      $scope.total_principal = $selected.principal;
      $scope.return_period = $selected.return_period;
      $scope.repayment_counter = $selected.repayment_counter;

      $rootScope.Interest_Repayment = $selected.interest_per_month;
      $rootScope.Installment_Repayment = $selected.installment;
      $rootScope.Principal_Repayment = $selected.principal_per_month;

      var a = Number($scope.return_period);
      var b = Number($scope.repayment_counter);
      var c = a - b;
      if (c == 1) {
        $scope.installment = $selected.current_balance;
        $scope.principal_per_month =
          $selected.current_balance - $selected.interest_per_month;
      } else {
        $scope.installment = $selected.installment;
        $scope.principal_per_month = $selected.principal_per_month;
      }

      // For Adding Loan Pre-CLosure Repayent
      var d = Number($selected.principal_per_month);
      var e = Number($selected.interest_per_month);
      var f = Number($selected.repayment_counter);
      var g = Number($selected.interest);
      var h = Number($selected.principal);

      var total_principal_outstanding = h - d * f;
      $scope.total_principal_outstanding = total_principal_outstanding;

      var total_interest_deducted = e * f;
      var expected_total_interest = g;
      var total_interest_remaining = g - e * f;
      var total_interest_preclosure =
        (total_interest_deducted / expected_total_interest) *
        total_interest_remaining;

      $scope.total_interest_preclosure = total_interest_preclosure;

      $scope.pre_closure_amount =
        total_principal_outstanding + total_interest_preclosure;
    };

    $scope.minusMultiplicationFactor = function () {
      var value = parseInt(
        document.getElementById("multiplicationFactor").value
      );
      value = isNaN(value) ? 1 : value;
      if (value > 1) {
        value--;
      }

      document.getElementById("multiplicationFactor").value = value;
      $scope.multiplication_factor = value;
      $scope.installment = value * $rootScope.Installment_Repayment;
      $scope.interest_per_month = value * $rootScope.Interest_Repayment;
      $scope.principal_per_month = value * $rootScope.Principal_Repayment;
    };

    $scope.plusMultiplicationFactor = function () {
      var value = parseInt(
        document.getElementById("multiplicationFactor").value
      );
      value = isNaN(value) ? 1 : value;

      var a = $scope.return_period;
      var b = $scope.repayment_counter;
      var c = a - b - 1;
      var d = a - b;

      value++;

      if (d > 1 && value < d) {
        $scope.multiplication_factor = value;
        document.getElementById("multiplicationFactor").value = value;

        $scope.installment = value * $rootScope.Installment_Repayment;
        $scope.principal_per_month = value * $rootScope.Principal_Repayment;
        $scope.interest_per_month = value * $rootScope.Interest_Repayment;
      } else if (value == d) {
        $scope.multiplication_factor = value;
        document.getElementById("multiplicationFactor").value = value;
        $scope.installment = $scope.current_balance;
        $scope.principal_per_month =
          $scope.current_balance - value * $rootScope.Interest_Repayment;
        $scope.interest_per_month = value * $rootScope.Interest_Repayment;
      }
    };

    $scope.getLoans = function () {
      LoansService.showLoan().success(function (data) {

        var approved_loans = data.loan;
        $scope.loans = [];

        angular.forEach(approved_loans, function (approved_loans) {
          if (
            approved_loans.is_approved == 4 &&
            approved_loans.current_balance > 0 &&
            approved_loans.issued == 1 &&
            approved_loans.membership_status == 1
          ) {
            $scope.loans.push(approved_loans);
          }
        });
      });
    };



    /* Benefits page */ 

    $scope.benefits_sample_data = [
      {
        "EFFECTIVE DATE": "2019-03-14",
        "MEMBER ID": "1",
        NAME: "Juma Emmanuel Agustino",
        BENEFIT: "Wedding",
        AMOUNT: "200000"
      }
    ];

    $scope.benefits_sample = function () {
      alasql(
        'SELECT * INTO XLSX("benefits-sample.xlsx",{headers:true}) FROM ?',
        [$scope.benefits_sample_data]
      );
    };


    $scope.importBenefitsExcel = function (sheet) {
      $upload
        .upload({
          url: "importBenefitsExcel",
          data: {
            sheet: sheet
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getBenefitTransactions();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Get benefits in Benefits page
    $scope.getBenefitTransactions = function() {
      MembersService.getBenefitTransactions().success(function (data) {
        $scope.benefits = data.benefits;
      });
    }


    $scope.getActiveBenefits = function(){
      BenefitsService.getActiveBenefits().success(function (data) {
        $scope.active_benefits = data.active_benefits;
      });
    }


    $scope.benefitTransaction = function (
      memberId,
      amount,
      benefitId,
      effective_date
    ) {
      return $http({
        method: "POST",
        url: "index.php/benefitTransaction/",
        params: {
          memberId: memberId,
          amount: amount,
          benefitId: benefitId,
          effective_date: effective_date
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getBenefitTransactions();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.clearBenefitModal = function (){
      if ($scope.members.selected) {
        $scope.members.selected = '';
      }
      if ($scope.active_benefits.selected){
        $scope.active_benefits.selected = '';
      }
      $scope.amount = null;
      $scope.effective_date = '';
      $scope.benefitForm.$setPristine();
      $scope.benefitForm.$setUntouched();
    }



    /* Withdraws page */

    $scope.withdrawal_sample_data = [
      {
        "EFFECTIVE DATE": "2019-03-14",
        "MEMBER ID": "1",
        NAME: "Juma Emmanuel Agustino",
        AMOUNT: "200000",
        "DEDUCTED ACCOUNT": "Saving"
      },
      {
        "EFFECTIVE DATE": "2019-03-14",
        "MEMBER ID": "1",
        NAME: "Juma Emmanuel Agustino",
        AMOUNT: "50000",
        "DEDUCTED ACCOUNT": "Deposit"
      }
    ];
    $scope.withdrawal_sample = function () {
      alasql(
        'SELECT * INTO XLSX("withdrawals-sample.xlsx",{headers:true}) FROM ?',
        [$scope.withdrawal_sample_data]
      );
    };


    $scope.importWithdrawalsExcel = function (sheet) {
      $upload
        .upload({
          url: "importWithdrawalsExcel",
          data: {
            sheet: sheet
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getWithdrawalTransactions();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Get withdrawals in Withdrawals page
    $scope.getWithdrawalTransactions = function() {
      MembersService.getWithdrawalTransactions().success(function (data) {
        $scope.withdrawals = data.withdrawals;
      });
    }


    $scope.checkBalance = function (memberId) {
      return $http({
        method: "POST",
        url: "index.php/checkBalance/",
        params: {
          memberId: memberId
        }
      }).success(function (data) {
        $scope.client = data.member;
        $scope.balance = data.balance;
      });
    };


    $scope.clearWithdrawalModal = function () {
      $scope.members.selected = "";
      $scope.effective_date = "";
      $scope.balance = null;
      $scope.amount = null;
      $scope.WithdrawalForm.$setPristine();
      $scope.WithdrawalForm.$setUntouched();
    };



    /* Shared Withdraws & Member Info page*/

    $scope.withDraw = function (memberId, amount, effective_date) {
      return $http({
        method: "POST",
        url: "index.php/withDraw/",
        params: {
          memberId: memberId,
          amount: amount,
          effective_date: effective_date
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getWithdrawalTransactions();

          MembersService.getMemberInfo(memberId).success(function (data) {
            $scope.transactions = data.transactions;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };



    /* Members page */

    $scope.members_sample_data = [
      {
        "FIRST NAME": "Juma",
        "MIDDLE NAME": "Emmanuel",
        "LAST NAME": "Agustino",
        "DATE OF BIRTH": "1977-08-03",
        "GENDER": "Male",
        "NATIONALITY": "Tanzanian",
        "IDENTITY TYPE": "National ID",
        "IDENTITY NUMBER": "297-52-9208",
        "EMAIL": "juma@email.com",
        "PHONE NUMBER": "0687774444",
        "CURRENT ADDRESS": "P.O. Box 2223, Arusha, Tanzania",
        "PERMANENT ADDRESS": "P.O. Box 1122, Moshi, Tanzania",
        "JOB TITLE": "Marketing Assistant",
        "EMPLOYER NAME": "Habari Node PLC",
        "DEPARTMENT": "Sales & Marketing",
        "CONTRACT TYPE": "Permanent",
        "EMPLOYMENT NUMBER": "77-270-7201",
        "BANK ACCOUNT": "5864242055",
        "BANK NAME": "CRDB",
        "BRANCH": "Arusha",
        "SUCCESSOR FIRST NAME": "Samuel",
        "SUCCESSOR MIDDLE NAME": "James",
        "SUCCESSOR LAST NAME": "Laizer",
        "SUCCESSOR DATE OF BIRTH": "1965-06-10",
        "SUCCESSOR GENDER": "Male",
        "SUCCESSOR NATIONALITY": "Tanzanian",
        "SUCCESSOR ID TYPE": "Passport",
        "SUCCESSOR ID NUMBER": "AB567898",
        "SUCCESSOR EMAIL": "samuel@email.com",
        "SUCCESSOR PHONE NUMBER": "0747747756",
        "SUCCESSOR CURRENT ADDRESS": "P.O. Box 1122, Arusha, Tanzania",
        "SUCCESSOR PERMANENT ADDRESS": "P.O. Box 1122, Arusha, Tanzania",
        "RELATIONSHIP": "Friend",
        "REGISTRATION FEE": "10000",
        "REGISTRATION DATE": "2018-01-15",
        "MEMBERSHIP STATUS": "Current",
        "MEMBERSHIP TERMINATION DATE": "",
        "MEMBERSHIP TERMINATION REASON": "",
      },
      {
        "FIRST NAME": "Mary",
        "MIDDLE NAME": "Neema",
        "LAST NAME": "Lukumay",
        "DATE OF BIRTH": "1991-04-09",
        "GENDER": "Female",
        "NATIONALITY": "Tanzanian",
        "IDENTITY TYPE": "Passport",
        "IDENTITY NUMBER": "AB896712",
        "EMAIL": "mary@email.com",
        "PHONE NUMBER": "0741236532",
        "CURRENT ADDRESS": "P.O. Box 1236, Arusha, Tanzania",
        "PERMANENT ADDRESS": "P.O. Box 7653, Mwanza, Tanzania",
        "JOB TITLE": "Accountant",
        "EMPLOYER NAME": "Habari Node PLC",
        "DEPARTMENT": "Accounts",
        "CONTRACT TYPE": "Permanent",
        "EMPLOYMENT NUMBER": "77-270-4322",
        "BANK ACCOUNT": "5864324564",
        "BANK NAME": "CRDB",
        "BRANCH": "Arusha",
        "SUCCESSOR FIRST NAME": "Jackson",
        "SUCCESSOR MIDDLE NAME": "Julius",
        "SUCCESSOR LAST NAME": "Lukumay",
        "SUCCESSOR DATE OF BIRTH": "1987-04-02",
        "SUCCESSOR GENDER": "Male",
        "SUCCESSOR NATIONALITY": "Tanzanian",
        "SUCCESSOR ID TYPE": "Passport",
        "SUCCESSOR ID NUMBER": "AB236543",
        "SUCCESSOR EMAIL": "jackson@email.com",
        "SUCCESSOR PHONE NUMBER": "0687547324",
        "SUCCESSOR CURRENT ADDRESS": "P.O. Box 1236, Arusha, Tanzania",
        "SUCCESSOR PERMANENT ADDRESS": "P.O. Box 1236, Arusha, Tanzania",
        "RELATIONSHIP": "Husband",
        "REGISTRATION FEE": "10000",
        "REGISTRATION DATE": "2018-05-21",
        "MEMBERSHIP STATUS": "Former",
        "MEMBERSHIP TERMINATION DATE": "2019-06-01",
        "MEMBERSHIP TERMINATION REASON": "Left the company",
      },
    ];

    $scope.members_sample = function () {
      alasql(
        'SELECT * INTO XLSX("members-sample.xlsx",{headers:true}) FROM ?',
        [$scope.members_sample_data]
      );
    };


    $scope.onFileSelect1 = function ($files) {
      $scope.photo = $files[0];

      // No photo validation
      if ($scope.photo.name !== undefined) {
        $scope.photoPresent = true;
      } else {
        $scope.photoPresent = false;
      }

      // photo size validation
      $scope.photoSizeKB = $scope.photo.size / 1000;
      $scope.photoSizeMB = $scope.photo.size / 1000000;

      // 20MB maximum photo size
      if ($scope.photo.size < 20000000) {
        $scope.photoSizeValid = true;
      } else {
        $scope.photoSizeValid = false;
      }

      // photo type validation
      var validFormats = ["bmp", "png", "jpg", "jpeg"];
      var ext = $scope.photo.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.photoTypeValid = validFormats.includes(ext);

      if ($scope.cameraPhoto !== null) {
        $scope.cameraPhoto = {};
      }
    };

    $scope.onFileSelect1Edit = function ($files) {
      $scope.photo_edit = $files[0];

      // No photo_edit validation
      if ($scope.photo_edit.name !== undefined) {
        $scope.photoEditPresent = true;
      } else {
        $scope.photoEditPresent = false;
      }

      // photo_edit size validation
      $scope.photoEditSizeKB = $scope.photo_edit.size / 1000;
      $scope.photoEditSizeMB = $scope.photo_edit.size / 1000000;

      // 20MB maximum photo_edit size
      if ($scope.photo_edit.size < 20000000) {
        $scope.photoEditSizeValid = true;
      } else {
        $scope.photoEditSizeValid = false;
      }

      // photo_edit type validation
      var validFormats = ["bmp", "png", "jpg", "jpeg"];
      var ext = $scope.photo_edit.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.photoEditTypeValid = validFormats.includes(ext);
    };

    $scope.onFileSelect2 = function ($files) {
      $scope.identityPhotocopy = $files[0];

      // No identityPhotocopy validation
      if ($scope.identityPhotocopy.name !== undefined) {
        $scope.identityPhotocopyPresent = true;
      } else {
        $scope.identityPhotocopyPresent = false;
      }

      // identityPhotocopy size validation
      $scope.identityPhotocopySizeKB = $scope.identityPhotocopy.size / 1000;
      $scope.identityPhotocopySizeMB = $scope.identityPhotocopy.size / 1000000;

      // 20MB maximum identityPhotocopy size
      if ($scope.identityPhotocopy.size < 20000000) {
        $scope.identityPhotocopySizeValid = true;
      } else {
        $scope.identityPhotocopySizeValid = false;
      }

      // identityPhotocopy type validation
      var validFormats = ["bmp", "png", "jpg", "jpeg", "pdf", "doc", "docx"];
      var ext = $scope.identityPhotocopy.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.identityPhotocopyTypeValid = validFormats.includes(ext);
    };

    $scope.onFileSelect2Edit = function ($files) {
      $scope.identityPhotocopy_edit = $files[0];

      // No identityPhotocopy_edit validation
      if ($scope.identityPhotocopy_edit.name !== undefined) {
        $scope.identityPhotocopyEditPresent = true;
      } else {
        $scope.identityPhotocopyEditPresent = false;
      }

      // identityPhotocopy_edit size validation
      $scope.identityPhotocopyEditSizeKB = $scope.identityPhotocopy_edit.size / 1000;
      $scope.identityPhotocopyEditSizeMB = $scope.identityPhotocopy_edit.size / 1000000;

      // 20MB maximum identityPhotocopy_edit size
      if ($scope.identityPhotocopy_edit.size < 20000000) {
        $scope.identityPhotocopyEditSizeValid = true;
      } else {
        $scope.identityPhotocopyEditSizeValid = false;
      }

      // identityPhotocopy_edit type validation
      var validFormats = ["bmp", "png", "jpg", "jpeg", "pdf", "doc", "docx"];
      var ext = $scope.identityPhotocopy_edit.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.identityPhotocopyEditTypeValid = validFormats.includes(ext);
    };

    $scope.onFileSelect3 = function ($files) {
      $scope.other = $files[0];

      // No other validation
      if ($scope.other.name !== undefined) {
        $scope.otherPresent = true;
      } else {
        $scope.otherPresent = false;
      }

      // other size validation
      $scope.otherSizeKB = $scope.other.size / 1000;
      $scope.otherSizeMB = $scope.other.size / 1000000;

      // 20MB maximum other size
      if ($scope.other.size < 20000000) {
        $scope.otherSizeValid = true;
      } else {
        $scope.otherSizeValid = false;
      }

      // other type validation
      var validFormats = ["bmp", "png", "jpg", "jpeg", "pdf", "doc", "docx"];
      var ext = $scope.other.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.otherTypeValid = validFormats.includes(ext);
    };

    $scope.onFileSelect3Edit = function ($files) {
      $scope.other_edit = $files[0];

      // No other_edit validation
      if ($scope.other_edit.name !== undefined) {
        $scope.otherEditPresent = true;
      } else {
        $scope.otherEditPresent = false;
      }

      // other_edit size validation
      $scope.otherEditSizeKB = $scope.other_edit.size / 1000;
      $scope.otherEditSizeMB = $scope.other_edit.size / 1000000;

      // 20MB maximum other_edit size
      if ($scope.other_edit.size < 20000000) {
        $scope.otherEditSizeValid = true;
      } else {
        $scope.otherEditSizeValid = false;
      }

      // other_edit type validation
      var validFormats = ["bmp", "png", "jpg", "jpeg", "pdf", "doc", "docx"];
      var ext = $scope.other_edit.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.otherEditTypeValid = validFormats.includes(ext);
    };

    // $scope.onFileSelect4 = function ($files) {
    //   $scope.photo2 = $files[0];

    //   // No photo2 validation
    //   if ($scope.photo2.name !== undefined) {
    //     $scope.photo2Present = true;
    //   } else {
    //     $scope.photo2Present = false;
    //   }

    //   // photo2 size validation
    //   $scope.photo2SizeKB = $scope.photo2.size / 1000;
    //   $scope.photo2SizeMB = $scope.photo2.size / 1000000;

    //   // 20MB maximum photo2 size
    //   if ($scope.photo2.size < 20000000) {
    //     $scope.photo2SizeValid = true;
    //   } else {
    //     $scope.photo2SizeValid = false;
    //   }

    //   // photo2 type validation
    //   var validFormats = ["bmp", "png", "jpg", "jpeg"];
    //   var ext = $scope.photo2.name
    //     .split(".")
    //     .pop()
    //     .toLowerCase();
    //   $scope.photo2TypeValid = validFormats.includes(ext);
    // };

    // $scope.onFileSelect4Edit = function ($files) {
    //   $scope.photo2_edit = $files[0];

    //   // No photo2_edit validation
    //   if ($scope.photo2_edit.name !== undefined) {
    //     $scope.photo2EditPresent = true;
    //   } else {
    //     $scope.photo2EditPresent = false;
    //   }

    //   // photo2_edit size validation
    //   $scope.photo2EditSizeKB = $scope.photo2_edit.size / 1000;
    //   $scope.photo2EditSizeMB = $scope.photo2_edit.size / 1000000;

    //   // 20MB maximum photo2_edit size
    //   if ($scope.photo2_edit.size < 20000000) {
    //     $scope.photo2EditSizeValid = true;
    //   } else {
    //     $scope.photo2EditSizeValid = false;
    //   }

    //   // photo2_edit type validation
    //   var validFormats = ["bmp", "png", "jpg", "jpeg"];
    //   var ext = $scope.photo2_edit.name
    //     .split(".")
    //     .pop()
    //     .toLowerCase();
    //   $scope.photo2EditTypeValid = validFormats.includes(ext);
    // };

    // $scope.onFileSelect5 = function ($files) {
    //   $scope.identityPhotocopy2 = $files[0];

    //   // No identityPhotocopy2 validation
    //   if ($scope.identityPhotocopy2.name !== undefined) {
    //     $scope.identityPhotocopy2Present = true;
    //   } else {
    //     $scope.identityPhotocopy2Present = false;
    //   }

    //   // identityPhotocopy2 size validation
    //   $scope.identityPhotocopy2SizeKB = $scope.identityPhotocopy2.size / 1000;
    //   $scope.identityPhotocopy2SizeMB = $scope.identityPhotocopy2.size / 1000000;

    //   // 20MB maximum identityPhotocopy2 size
    //   if ($scope.identityPhotocopy2.size < 20000000) {
    //     $scope.identityPhotocopy2SizeValid = true;
    //   } else {
    //     $scope.identityPhotocopy2SizeValid = false;
    //   }

    //   // identityPhotocopy2 type validation
    //   var validFormats = ["bmp", "png", "jpg", "jpeg", "pdf", "doc", "docx"];
    //   var ext = $scope.identityPhotocopy2.name
    //     .split(".")
    //     .pop()
    //     .toLowerCase();
    //   $scope.identityPhotocopy2TypeValid = validFormats.includes(ext);
    // };

    // $scope.onFileSelect5Edit = function ($files) {
    //   $scope.identityPhotocopy2_edit = $files[0];

    //   // No identityPhotocopy2_edit validation
    //   if ($scope.identityPhotocopy2_edit.name !== undefined) {
    //     $scope.identityPhotocopy2EditPresent = true;
    //   } else {
    //     $scope.identityPhotocopy2EditPresent = false;
    //   }

    //   // identityPhotocopy2_edit size validation
    //   $scope.identityPhotocopy2EditSizeKB = $scope.identityPhotocopy2_edit.size / 1000;
    //   $scope.identityPhotocopy2EditSizeMB = $scope.identityPhotocopy2_edit.size / 1000000;

    //   // 20MB maximum identityPhotocopy2_edit size
    //   if ($scope.identityPhotocopy2_edit.size < 20000000) {
    //     $scope.identityPhotocopy2EditSizeValid = true;
    //   } else {
    //     $scope.identityPhotocopy2EditSizeValid = false;
    //   }

    //   // identityPhotocopy2_edit type validation
    //   var validFormats = ["bmp", "png", "jpg", "jpeg", "pdf", "doc", "docx"];
    //   var ext = $scope.identityPhotocopy2_edit.name
    //     .split(".")
    //     .pop()
    //     .toLowerCase();
    //   $scope.identityPhotocopy2EditTypeValid = validFormats.includes(ext);
    // };

    $scope.onFileSelect6 = function ($files) {
      $scope.sheet = $files[0];

      $scope.filename = $scope.sheet.name;

      // No file validation
      if ($scope.sheet.name !== undefined) {
        $scope.filePresent = true;
      } else {
        $scope.filePresent = false;
      }

      // File size validation
      // Filesize in KB
      $scope.filesizeKB = $scope.sheet.size / 1000;
      // Filesize in MB
      $scope.filesizeMB = $scope.sheet.size / 1000000;

      // 20MB maximum file size
      if ($scope.sheet.size < 20000000) {
        $scope.fileSizeValid = true;
      } else {
        $scope.fileSizeValid = false;
      }

      // File type validation
      var validFormats = ["xlsx"];
      var ext = $scope.sheet.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.fileTypeValid = validFormats.includes(ext);
    };
    $scope.onFileSelect7 = function ($files) {
      $scope.receipt_copy = $files[0];

      $scope.filename = $scope.receipt_copy.name;

      // No file validation
      if ($scope.receipt_copy.name !== undefined) {
        $scope.filePresent = true;
      } else {
        $scope.filePresent = false;
      }

      // File size validation
      // Filesize in KB
      $scope.filesizeKB = $scope.receipt_copy.size / 1000;
      // Filesize in MB
      $scope.filesizeMB = $scope.receipt_copy.size / 1000000;

      // 20MB maximum file size
      if ($scope.receipt_copy.size < 20000000) {
        $scope.fileSizeValid = true;
      } else {
        $scope.fileSizeValid = false;
      }

      // File type validation
      var validFormats = ["bmp", "png", "jpg", "jpeg", "pdf"];
      var ext = $scope.receipt_copy.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.fileTypeValid = validFormats.includes(ext);
    };


    $scope.importMemberExcel = function (sheet) {
      $upload
        .upload({
          url: "importMemberExcel",
          data: {
            sheet: sheet
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.showMembers().success(function (data) {
            $scope.members = data.members;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.getActiveRegistrationFee = function () {
      RegistrationFeesService.getActiveRegistrationFee().then(function (resp) {
        $scope.fee_save = resp.data.active_registration_fee.registration_fee;
      });
    };


    $scope.getDepartments = function () {
      DepartmentsService.getDepartments().success(function (data) {
        $scope.departments = data.departments;
      });
    }


    $scope.saveMember = function (
      fee,
      formdata,
      photo,
      identityPhotocopy,
      other,
      // photo2,
      // identityPhotocopy2,
      cameraPhoto
    ) {
      $upload
        .upload({
          url: "saveMember",
          data: {
            fee: fee,
            formdata: formdata,
            photo: photo,
            identityPhotocopy: identityPhotocopy,
            other: other,
            // photo2: photo2,
            // identityPhotocopy2: identityPhotocopy2,
            cameraPhoto: cameraPhoto
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.showMembers().success(function (data, status) {
            $scope.members = data.members;
          });

          $('#addMemberModal').modal('hide');
          $scope.register_form_counter = 0;

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.clearRegisterForm = function () {
      $scope.formdata = null;
      $scope.photo = {};
      $scope.identityPhotocopy = {};
      $scope.other = {};
      // $scope.photo2 = {};
      // $scope.identityPhotocopy2 = {};
      $scope.cameraPhoto = undefined;
      angular.element("input[type='file']").val(null);
      var img = document.querySelector('#camera');
      img.src = "";
      $scope.Register.$setPristine();
      $scope.Register.$setUntouched();
    }

    $scope.updateMember = function (
      formdata,
      photo,
      identityPhotocopy,
      attachment
      // ,
      // photo2,
      // identityPhotocopy2
    ) {
      $upload
        .upload({
          url: "updateMember",
          data: {
            formdata: formdata,
            photo: photo,
            identityPhotocopy: identityPhotocopy,
            attachment: attachment
            // ,
            // photo2: photo2,
            // identityPhotocopy2: identityPhotocopy2
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.showMembers().success(function (data, status) {
            $scope.members = data.members;
          });

          $('#editMemberModal').modal('hide');
          $scope.edit_member_form_counter = 0;

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteMember = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteMember",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.showMembers().success(function (data) {
            $scope.members = data.members;
          });
          
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.terminateMembership = function (formdata) {
      return $http({
        method: "POST",
        url: "index.php/terminateMembership",
        params: { formdata: formdata }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.showMembers().success(function (data) {
            $scope.members = data.members;
          });
          
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.renewMembership = function (formdata) {
      return $http({
        method: "POST",
        url: "index.php/renewMembership",
        params: { formdata: formdata }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.showMembers().success(function (data) {
            $scope.members = data.members;
          });
          
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $rootScope.activate = function (id, $index, serial) {
      MembersService.activate(id, $index)
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // $scope.members = data.members;
        })
        // .error(function () {
        //   alert("something went wrong");
        // });
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });

      MembersService.showMembers().success(function (data) {
        $scope.members = data.members;
      });
    };


    $rootScope.deactivate = function (id, $index) {
      MembersService.deactivate(id, $index) 
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });
        })
        // .error(function () {
        //   alert("something went wrong");
        // });
        .error(function(response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });

        // $scope.members = data.members;
        MembersService.showMembers().success(function (data) {
          $scope.members = data.members;
        });
    };


    $scope.uploadCapturedImage = function () {
      $scope.cameraPhoto = document.querySelector("#camera").src;

      // No camera photo validation
      if ($scope.cameraPhoto !== undefined) {
        $scope.cameraPhotoPresent = true;
      } else {
        $scope.cameraPhotoPresent = false;
      }

      // Remove 'Photo' if an image is captured
      if ($scope.photo != null) {
        $scope.photo = {};
      }
    };



    /* Shared Members & Members Info pages */ 

    $scope.getMemberDetails = function (memberId) {
      return $http({
        method: "POST",
        url: "index.php/getMemberDetails/",
        params: { memberId: memberId }
      }).success(function (data) {
        $scope.formdata = data.member;
      });
    };



    /* Members Info page */

    // Filter in Member Info page
    $scope.trans_type2 = {};
    $scope.trans_type2.selected = { label: "All", value: "" };
    $scope.trans_types2 = [
      { label: "All", value: "" },
      { label: "Benefits", value: "Benefit" },
      { label: "Contributions", value: "Contribution" },
      { label: "Deposits", value: "Deposit" },
      { label: "Dividends", value: "Dividend" },
      { label: "Loans", value: "Loan" },
      { label: "Registration", value: "Registration" },
      { label: "Repayments", value: "Repayment" },
      { label: "Savings", value: "Saving" },
      { label: "Shares", value: "Share" }
    ];


    // Adding transaction in Members Info page
    $scope.getAddMemberTransactionState = function () {
      var deductions = $scope.active_deductions;

      angular.forEach(deductions, function (deductions) {
        if (deductions.transaction_type == $scope.transaction.type) {
          $scope.min_amount = deductions.min_amount;
          $scope.max_amount = deductions.max_amount;
        }
      });

      var amount = Number($scope.transaction.amount);
      var max_amount = Number($scope.max_amount);
      var min_amount = Number($scope.min_amount);
      $scope.above_max = amount > max_amount;
      $scope.below_min = amount < min_amount;
    };


    $scope.saveContributionMemberInfo = function (
      formdata,
      memberId,
      receipt_copy
    ) {
      $upload
        .upload({
          url: "saveContribution",
          data: {
            formdata: formdata,
            memberId: memberId,
            receipt_copy: receipt_copy
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // Repopulate table
          MembersService.getMemberInfo(memberId).success(function (data) {
            $scope.transactions = data.transactions;
          });

          // Clear modal
          $scope.clearAddTransactionModal();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Clear Save Transaction modal
    $scope.clearAddTransactionModal = function() {
      $scope.transaction = '';
      $scope.formdata = '';
      $scope.min_amount = null;
      $scope.max_amount = null;
      angular.element("input[type='file']").val(null);
      $scope.receipt_copy = {};
      $scope.AddTransactionForm.$setPristine();
      $scope.AddTransactionForm.$setUntouched();
    }


    $scope.clearWithdrawalMemberInfoModal = function () {
      $scope.effective_date = "";
      $scope.amount = null;
      $scope.withdrawTransaction.$setPristine();
      $scope.withdrawTransaction.$setUntouched();
    };


    $rootScope.editRow = function (id, variable, field, table) {
      MembersService.editRow(id, variable, field, table)
        .success(function (response) { 
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $rootScope.getMemberInfo = function (memberId, a) {
      $scope.checkBalance(memberId);
      MembersService.getMemberInfo(memberId, a)
        .success(function (data, status) {
          if (a) {
            $state.go("#/members/info/:memberId", { memberId: data.member.id });
          }
          $scope.rowNumber.selected = { label: 10, value: 10 };
          $scope.member = data.member;
          $scope.dividend = data.dividends;
          $scope.successor = data.successor;
          $scope.contact = data.contact;
          $scope.employment = data.employment;
          $scope.transactions = data.transactions;
          $scope.loans = data.loans;
          $scope.statement = data.statement;
          $scope.share = data.share;
          $scope.deposit = data.in_deposit;
          $scope.saving = data.in_saving;
          $scope.contribution = data.in_contribution;
          $scope.deposit1 = data.out_deposit;
          $scope.saving1 = data.out_saving;
          $scope.contribution1 = data.out_contribution;
          var dividend = Number($scope.dividend || 0);
          var deposit = Number($scope.deposit || 0);
          var saving = Number($scope.saving || 0);
          var share = Number($scope.share || 0);
          var contribution = Number($scope.contribution || 0);
          var deposit1 = Number($scope.deposit1 || 0);
          var saving1 = Number($scope.saving1 || 0);
          var contribution1 = Number($scope.contribution1 || 0);
          $scope.nextId = data.nextId;
          $scope.prevId = data.prevId;
          $scope.sum = deposit + saving + contribution + share + dividend;
          $scope.sum1 = deposit1 + saving1 + contribution1;
          $scope.available_amout = $scope.sum - $scope.sum1;

          if (data.member.photo != null) {
            $scope.photoExtension = $scope.checkPdfExtension(data.member.photo);
          }

          if (data.member.identity_photocopy != null) {
            $scope.identityPhotocopyExtension = $scope.checkPdfExtension(
              data.member.identity_photocopy
            );
          }

          if (data.member.attachment != null) {
            $scope.attachmentExtension = $scope.checkPdfExtension(
              data.member.attachment
            );
          }

          if (data.successor.photo != null) {
            $scope.successorPhotoExtension = $scope.checkPdfExtension(
              data.successor.photo
            );
          }

          if (data.successor.identity_photocopy != null) {
            $scope.successoridentityPhotocopyExtension = $scope.checkPdfExtension(
              data.successor.identity_photocopy
            );
          }

          $scope.receiptExtension = data.transactions.receiptExtension;
        })
        .error(function (data, status) {
          alert(data);
        });
    };


    $scope.getMemberInfoOnly = function () {
      MembersService.getMemberInfoOnly()
        .success(function (data, status) {
          $state.go("#/members/memberinfo");
          $scope.rowNumber.selected = { label: 10, value: 10 };

          $scope.checkBalance(data.member.id);

          $scope.member = data.member;
          $scope.dividend = data.dividends;
          $scope.successor = data.successor;
          $scope.contact = data.contact;
          $scope.employment = data.employment;
          $scope.transactions = data.transactions;
          $scope.loans = data.loans;
          $scope.statement = data.statement;
          $scope.share = data.share;
          $scope.deposit = data.in_deposit;
          $scope.saving = data.in_saving;
          $scope.contribution = data.in_contribution;
          $scope.deposit1 = data.out_deposit;
          $scope.saving1 = data.out_saving;
          $scope.contribution1 = data.out_contribution;
          var dividend = Number($scope.dividend || 0);
          var deposit = Number($scope.deposit || 0);
          var saving = Number($scope.saving || 0);
          var share = Number($scope.share || 0);
          var contribution = Number($scope.contribution || 0);
          var deposit1 = Number($scope.deposit1 || 0);
          var saving1 = Number($scope.saving1 || 0);
          var contribution1 = Number($scope.contribution1 || 0);

          $scope.sum = deposit + saving + contribution + share + dividend;
          $scope.sum1 = deposit1 + saving1 + contribution1;
          $scope.available_amout = $scope.sum - $scope.sum1;

          $scope.photoExtension = $scope.checkPdfExtension(data.member.photo);
          $scope.identityPhotocopyExtension = $scope.checkPdfExtension(
            data.member.identity_photocopy
          );
          $scope.attachmentExtension = $scope.checkPdfExtension(
            data.member.attachment
          );
          $scope.successorPhotoExtension = $scope.checkPdfExtension(
            data.successor.photo
          );
          $scope.successoridentityPhotocopyExtension = $scope.checkPdfExtension(
            data.successor.identity_photocopy
          );

          $scope.receiptExtension = data.transactions.receiptExtension;
        })
        .error(function (data, status) {
          alert(data);
        });
    };


    $scope.uploadMemberPhoto = function (id, photo) {
      $upload
        .upload({
          url: "uploadMemberPhoto",
          data: {
            id: id,
            photo: photo
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.member = data.member;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.uploadMemberIdentityDoc = function (id, identityPhotocopy) {
      $upload
        .upload({
          url: "uploadMemberIdentityDoc",
          data: {
            id: id,
            identityPhotocopy: identityPhotocopy
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.member = data.member;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.uploadMemberOtherAttachment = function (id, other) {
      $upload
        .upload({
          url: "uploadMemberOtherAttachment",
          data: {
            id: id,
            other: other
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.member = data.member;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.uploadSuccessorsPhoto = function (id, photo2) {
      $upload
        .upload({
          url: "uploadSuccessorsPhoto",
          data: {
            id: id,
            photo2: photo2
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.successor = data.successor;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.uploadSuccessorsIdentityDoc = function (id, identityPhotocopy2) {
      $upload
        .upload({
          url: "uploadSuccessorsIdentityDoc",
          data: {
            id: id,
            identityPhotocopy2: identityPhotocopy2
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.successor = data.successor;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteMemberPhoto = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteMemberPhoto",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.member = data.member;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteMemberIdentityDoc = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteMemberIdentityDoc",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.member = data.member;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteMemberOtherAttachment = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteMemberOtherAttachment",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.member = data.member;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteSuccessorsPhoto = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteSuccessorsPhoto",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.successor = data.successor;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteSuccessorsIdentityDoc = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteSuccessorsIdentityDoc",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          MembersService.getMemberInfo(id).success(function (data) {
            $scope.successor = data.successor;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };



    /* Redundant code */

    // $scope.deleteTrans = function (transactionId) {
    //   $upload
    //     .upload({
    //       url: "deleteTrans",
    //       data: {
    //         transactionId: transactionId
    //       }
    //     })
    //     .success(function (response) {
    //       notify({
    //         message: response,
    //         template: "pages/notify/success.html",
    //         position: "center"
    //       });
    //     })
    //     .error(function (response) {
    //       notify({
    //         message: response,
    //         template: "pages/notify/error.html",
    //         position: "center"
    //       });
    //     });
    // };


    // $scope.export = function () {
    //   return $http({
    //     method: "POST",
    //     url: "index.php/export"
    //   }).success(function (data) {
    //     $scope.formdata = data.member;
    //   });
    // };


    // $scope.saveTransaction = function (
    //   formdata,
    //   memberId,
    //   receipt_copy,
    //   installment,
    //   principal_per_month,
    //   interest_per_month,
    //   multiplication_factor,
    //   repayment_month
    // ) {
    //   angular.extend(
    //     formdata,
    //     { installment: installment },
    //     { principal_per_month: principal_per_month },
    //     { interest_per_month: interest_per_month },
    //     { multiplication_factor: multiplication_factor },
    //     { repayment_month: repayment_month }
    //   );
    //   $upload
    //     .upload({
    //       url: "saveTransaction",
    //       data: {
    //         formdata: formdata,
    //         memberId: memberId,
    //         receipt_copy: receipt_copy
    //       }
    //     })
    //     .success(function (response) {
    //       notify({
    //         message: response,
    //         template: "pages/notify/success.html",
    //         position: "center"
    //       });

    //       // MembersService.showTransactions().success(function (data) {
    //       //   $scope.transactionsData = data.transactionsData;
    //       //   $scope.withdrawals = data.withdrawals;
    //       //   $scope.repayments = data.repayments;
    //       //   $scope.benefits = data.benefits;
    //       // });

    //       // Repopulate tables
    //       $scope.getContributionTransactions();
    //       $scope.getRepaymentTransactions();
    //       $scope.getBenefitTransactions();
    //       $scope.getWithdrawalTransactions();

    //       MembersService.getMemberInfo(memberId).success(function (data) {
    //         $scope.transactions = data.transactions;
    //       });

    //       // Clear modals
    //       $scope.clearAddContributionModal();
    //       $scope.clearAddTransactionModal();

    //     })
    //     .error(function (response) {
    //       notify({
    //         message: response,
    //         template: "pages/notify/error.html",
    //         position: "center"
    //       });
    //     });
    // };


    // MembersService.showTransactions().success(function (data, status) {
    //   $scope.transactionsData = data.transactionsData;
    //   $scope.withdrawals = data.withdrawals;
    //   $scope.repayments = data.repayments;
    //   $scope.benefits = data.benefits;
    // });


    // $scope.HNPLC = function () {
    //   var date = "2019-02-01 00:00:00";
    //   return date;
    // };


  }
]);
